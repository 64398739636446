<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>每月数据统计</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="top" style="margin-left: -10px;">
                <span style="margin-left:28px;">国家：</span>
                <el-select v-model="nation" placeholder="请选择国家" @change="checkdata">
                    <el-option :label="$t('buxian')" :value="null"></el-option>
                    <el-option v-for="item in nationlist" :key="item.values" :label="item.name" :value="item.values"></el-option>
                </el-select>
                <span style="margin-left:8px;">年份：</span>
                <el-date-picker v-model="year" type="year" placeholder="请选择年份" @change="checkdata"></el-date-picker>
            </div>
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <el-table :data="list"
                        :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                        <el-table-column prop="time" label="时间" :width="160"></el-table-column>
                        <el-table-column prop="collectionService" label="代收服务费" :width="160"></el-table-column>
                        <el-table-column prop="collectionNumber" label="代收笔数"></el-table-column>
                        <el-table-column prop="paymentService" label="代付服务费"></el-table-column>
                        <el-table-column prop="paymentNumber" label="代付笔数"></el-table-column>
                        <el-table-column prop="totalService" label="总服务费"></el-table-column>
                        <el-table-column prop="totalStrokes" label="总笔数"></el-table-column>
                        <el-table-column prop="totalTax" label="总税费"></el-table-column>
                        <el-table-column prop="withdrawalService" label="提现服务费"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import apiurl from "@/api/public"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {
        LOAD_CHILDREN_OPTIONS
    } from '@riophae/vue-treeselect'
    export default {
        components: {
            Treeselect
        },
        data() {
            var rolesValid = (rule, value, callback) => {
                callback()
            }
            return {
                loading: false,
                list: [],
                nation: 'mx',
                year:'',
                nationlist: [],
            }
        },
        created() {
            this.user = JSON.parse(localStorage.getItem("userinfo"))
            //this.getList()
            this.getnation()
        },
        methods: {
            checkdata(){
                this.getList()
            },
            getnation() {
                _api.get(apiurl.nationlist, {}).then(res => {
                    let newarr = []
                    for(let item of res.data){
                        newarr.push(item)
                    }
                    this.nationlist = newarr
                })
            },
            getList() {
                this.loading = true
                _api.get(apiurl.monthly, {
                    nation: this.nation,
                    year: this.year.getFullYear(),
                }).then(res => {
                    this.loading = false
                    this.list = res.data
                })
            },
           
        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>